import React, {useContext} from 'react';
import Image from 'gatsby-image';
import {useHeaderImage} from '../../shared/Hooks/Headers';
import {useTextImage} from '../../shared/Hooks/HowWeWork/useTextImage';
import {useProcessTimelineImage} from '../../shared/Hooks/HowWeWork/useProcessTimelineImage';
import {ThemeContext} from 'styled-components';
import {Paragraph, NormalHeader} from '../../components/Typography';
import {StyledImage} from '../../sections/Case/CaseCard/styles';
import {First, Second, Fifth, Fourth, Third} from '../../components/Icons/Timeline';
import {GreenText} from '../../sections/HowWeWork/BottomComponent/styles';
import {useEnBackgroundImage} from '../../shared/Hooks/Background/useEnBackgroundImage';

export const useData = () => {
  const headers = useHeaderImage();
  const textImages = useTextImage();
  const processImages = useProcessTimelineImage();
  const themeContext = useContext(ThemeContext);
  const backgroundImgs = useEnBackgroundImage();

  return {
    seo: {
      title: 'How we work',
    },
    header: {
      image:
        <Image fluid={headers.howWeWorkHeader.childImageSharp.fluid}/>,
      section: 'How we work',
      title: <>Innovative concepts and possibilities</>,
    },
    textSection: {
      images: [
        <Image
          objectFit="cover"
          fluid={textImages.image_1.childImageSharp.fluid}
        />,
        <Image
          objectFit="cover"
          fluid={textImages.image_2.childImageSharp.fluid}
        />,
      ],
      text_1: (
        <>
          Several departments building the complete ecosystem of services around the project are involved in the related cycles of work surrounding it. Designers, constructors, logisticians, and assemblers - each of the teams cooperates on the product in an active and substantial way, under the coordination of the customer service department. Thanks to multi-level cooperation, we always offer products which are well-designed in visual, practical, and usable terms, adjusted to the expectations and specifications of the client.
        </>
      ),
      text_2: (
        <>
          We use diversified manufacturing technologies allowing for the manufacture of POS materials from furniture board, plywood, plastic (HIPS, PS, PMMA), metal, and composite material (dibond).
        </>
      ),
      text_3: (
        <>
          We employ specialists with real expertise in their given professions, who take a&nbp;passionate approach to their projects, in all departments.
        </>
      ),
    },
    processSection: {
      title: <>How our products are made?</>,
      parallaxText: (
        <Image fixed={backgroundImgs.process.childImageSharp.fixed}/>
      ),
      data: [
        {
          id: '01',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Definition of needs and brainstorming
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              We conduct discussions, listening carefully to the requirements of the client and determining the manner of their fulfillment. In the conducted consultations, we share the observations and suggestions based on prior knowledge and experience.
            </Paragraph>
          ),
          icon:
            <First height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.brainstorm.childImageSharp.fluid} id={'01'}/>
          ),
        },
        {
          id: '02',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Visualisation and design
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Our materials are created thanks to close cooperation of constructors and designers, guaranteeing an optimal balance between the technical and aesthetic aspects of the offered solution.
            </Paragraph>
          ),
          icon:
            <Second height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage
              fluid={processImages.visualisation.childImageSharp.fluid}
              id={'01'}
            />
          ),
        },
        {
          id: '03',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Technical development
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              After the acceptance stage, we develop an accurate digital model of the project, taking into account the materials and the technical aspects connected with storage and transport.
            </Paragraph>
          ),
          icon:
            <Third height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.development.childImageSharp.fluid} id={'01'}/>
          ),
        },
        {
          id: '04',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Prototype and production
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              Before production, we present the client with a prototype, from which the presented solutions are accepted.
            </Paragraph>
          ),
          icon:
            <Fourth height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.production.childImageSharp.fluid} id={'01'}/>
          ),
        },
        {
          id: '05',
          title: (
            <NormalHeader bolded color={themeContext.color.green}>
              Implementation
            </NormalHeader>
          ),
          description: (
            <Paragraph>
              We offer the full set of logistic services for the completed projects. Storage, transport, and installation at the POS, as well as guarantee and post-guarantee care of the product.
            </Paragraph>
          ),
          icon:
            <Fifth height={'64px'} color={themeContext.color.gray}/>,
          img: (
            <StyledImage fluid={processImages.deployment.childImageSharp.fluid} id={'01'}/>
          ),
        },
      ],
    },
    departments: {
      title: 'Our departments',
      data: [
        {
          title: 'Design department',
          text:
            'Design department – this team cooperates closely with the customer service department and the technical (production) department in the development of every new project. Thanks to this, we always offer a product which is well thought out in visual, practical, and usable terms, adjusted to the expectations and specifications of the client.',
        },
        {
          title: 'echnical Department',
          text:
            'We follow market trends, using proven solutions and patents developed in-house. We select the solutions which are the most optimal in terms of ergonomics and practicality.',
        },
        {
          title: 'Prototyping and Manufacturing Department',
          text:
            'We have the plant and experience allowing for manufacture of displays from wood, metal, and plastic in one facility. Before the commencement of every serial production, we prepare the prototypes showing the target appearance and functionality. We also manufacture sample boards, delivering a complete set to the POS and ensuring a complete manufacturing cycle with distribution.',
        },
        {
          title: 'Logistics Department',
          text: (
            <>
              Depending on the specifics of the stand, we optimise the construction and manner of packing starting straight from the design stage. We prepare our materials, inter alia, in terms of TIR transport, our own trucks, or third-party suppliers of the client. <br/>
              We operate all over Europe.
            </>
          ),
        },
      ],
    },

    bottomComponent: {
      text: <>
        <GreenText>Within the logistic services</GreenText> not only do we assemble the stands but we also assemble
        <GreenText> the sales zones</GreenText> (shop in
        shop) at the POS in a complex way. We design the layout, matching the interior of the shops and adapting it to the already existing solutions.
      </>,
    },
  };
};
